import { TAdItem, TResponsiveItem, TSizeItem } from '../types'

const REFRESH_KEY = 'refresh'
const REFRESH_VALUE = 'true'

// Number of seconds to wait after the slot becomes viewable.
const SECONDS_TO_WAIT_AFTER_VIEWABLE = 60

const eventQueue: any[] = []

const googleTag = (): typeof googletag | undefined => {
  const global = window
  global.googletag = global.googletag || {}

  return global.googletag
}

export const dfp = {
  createSlots: (
    ads: TAdItem[],
    enableLazyLoad: boolean,
    enableRefresh: boolean,
    enableSingleRequest: boolean,
  ) => {
    try {
      const gt = googleTag()

      if (!gt) {
        return
      }

      gt.cmd.push(() => {
        gt.pubads().collapseEmptyDivs()
        const alreadyExistSlots = gt.pubads().getSlots()

        ads.forEach(({ slotId, divId, sizeMappings }: TAdItem) => {
          if (
            alreadyExistSlots.some((slot) => slot.getSlotElementId() === divId)
          ) {
            return
          }
          // eslint-disable-next-line no-undef
          let responsiveMappings: googletag.SizeMappingArray | null = null
          let mappings = sizeMappings

          if (sizeMappings.length > 0) {
            const [firstSizeMapping] = sizeMappings

            if (
              typeof firstSizeMapping === 'object' &&
              !Array.isArray(firstSizeMapping) &&
              !!firstSizeMapping.breakpoint &&
              !!firstSizeMapping.sizes
            ) {
              const sizeMappingsResponsive = sizeMappings as TResponsiveItem[]
              const sizeMapping = gt.sizeMapping()

              mappings = []

              sizeMappingsResponsive.forEach(({ breakpoint, sizes }) => {
                sizeMapping.addSize(breakpoint, sizes)

                const [firstSize] = sizes

                if (!!firstSize && Array.isArray(firstSize)) {
                  ;(mappings as TSizeItem[]).push(...(sizes as TSizeItem[]))
                } else {
                  ;(mappings as TSizeItem[]).push(sizes as TSizeItem)
                }
              })

              responsiveMappings = sizeMapping.build()
            }
          }

          const slot = gt
            // eslint-disable-next-line no-undef
            .defineSlot(slotId, mappings as googletag.GeneralSize, divId)
            ?.setTargeting(REFRESH_KEY, REFRESH_VALUE)
            .addService(gt.pubads())

          if (responsiveMappings) {
            slot?.defineSizeMapping(responsiveMappings)
          }
        })

        if (enableRefresh) {
          gt.pubads().addEventListener('impressionViewable', (event) => {
            const slot = event.slot

            if (slot.getTargeting(REFRESH_KEY).indexOf(REFRESH_VALUE) > -1) {
              setTimeout(() => {
                gt.pubads().refresh([slot])
              }, SECONDS_TO_WAIT_AFTER_VIEWABLE * 1000)
            }
          })
        }

        if (enableLazyLoad) {
          // Enable lazyLoad with some good defaults
          gt.pubads().enableLazyLoad({
            fetchMarginPercent: 500,
            renderMarginPercent: 200,
            mobileScaling: 2.0,
          })
        }

        if (enableSingleRequest) {
          gt.pubads().enableSingleRequest()
        }

        gt.enableServices()
      })
    } catch (error) {
      console.error('error in createSlots')
      console.error(error)
    }
  },
  showSlot: (divId: string, onAdIsNull?: () => void) => {
    try {
      const gt = googleTag()

      if (!gt) {
        return
      }

      const handleSlotRenderEnded = (event) => {
        const isTargetSlot = event.slot.getSlotElementId() === divId
        if (isTargetSlot === false) {
          return
        }

        const isEmpty = event.isEmpty
        const targetAdIsNull =
          isEmpty && event.slot.getResponseInformation() === null
        if (onAdIsNull && targetAdIsNull) {
          onAdIsNull()
          return
        }

        if (isEmpty) {
          console.debug('is empty! refresh: ', divId)
          gt.pubads().refresh([event.slot])
        }
      }

      gt.cmd.push(() => {
        gt.display(divId)
        gt.pubads().addEventListener('slotRenderEnded', handleSlotRenderEnded)
        eventQueue.push(handleSlotRenderEnded)
      })
    } catch (error) {
      console.error('error in showSlot')
      console.error(error)
    }
  },
  removeSlots: () => {
    try {
      const gt = googleTag()
      if (!gt) {
        return
      }

      gt.cmd.push(() => {
        eventQueue.forEach((event) => {
          gt.pubads().removeEventListener('slotRenderEnded', event)
        })
        gt.destroySlots()
      })
    } catch (error) {
      console.error('error in removeSlots')
      console.error(error)
    }
  },
}
