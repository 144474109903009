import React, { FC, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { dfp } from '../modules/dfp'
import { TAdItem } from '../types'
import { GoogleAdManagerContext } from './GoogleAdManagerContext'

type TGoogleAdManagerProviderProps = {
  ads: TAdItem[]
  debug?: boolean
  enableLazyLoad?: boolean
  enableRefresh?: boolean
  /**
   * @description 광고 singleRequest 옵션 활성화 (최대 30개의 슬록까지만 가능합니다.)
   */
  enableSingleRequest?: boolean
}

export const GoogleAdManagerProvider: FC<TGoogleAdManagerProviderProps> = ({
  ads,
  children,
  debug = false,
  enableLazyLoad = false,
  enableRefresh = false,
  enableSingleRequest = false,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [gptFirstLoadedTrigger, setGptFirstLoadedTrigger] = useState(false)

  const router = useRouter()

  // Create ad slots
  useEffect(() => {
    setIsLoading(true)

    const isInitGoogletag =
      typeof window !== 'undefined' && typeof window.googletag !== 'undefined'

    // not loaded gpt script
    if (!isInitGoogletag && !gptFirstLoadedTrigger) {
      return
    }
    dfp.createSlots(ads, enableLazyLoad, enableRefresh, enableSingleRequest)

    setIsLoading(false)

    const handleRouteChangeStart = () => {
      setIsLoading(true)
      dfp.removeSlots()
    }

    const handleRouteChangeComplete = () => {
      setIsLoading(false)
    }

    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [
    ads,
    enableLazyLoad,
    enableRefresh,
    enableSingleRequest,
    gptFirstLoadedTrigger,
  ])

  // Enable debug console if possible
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)

    if (!!debug && !searchParams.has('google_console')) {
      searchParams.append('google_console', '1')
      window.location = `${window.location.pathname}?${searchParams}` as any
    }

    if (!debug && searchParams.has('google_console')) {
      searchParams.delete('google_console')

      const search = `${searchParams}`.length > 0 ? `?${searchParams}` : ''
      window.location = `${window.location.pathname}${search}` as any
    }
  }, [debug])

  return (
    <>
      <GoogleAdManagerContext.Provider value={{ isLoading }}>
        {children}
      </GoogleAdManagerContext.Provider>
      <Script
        src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        onLoad={() => {
          setGptFirstLoadedTrigger(true)
        }}
        async
      />
    </>
  )
}
