import { ImageLoader, ImageLoaderProps } from 'next/image'

type TOptimizeImageUrlProps = {
  url?: string
  width: number | string
  height: number | string
}

export const optimizeImageUrl = (options: TOptimizeImageUrlProps) => {
  if (!options.url) return ''
  return `${options.url}?d=${options.width}x${options.height}`
}

export const imageLoader: ImageLoader = ({ src }: ImageLoaderProps) => {
  return `${src}`
}
