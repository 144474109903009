import { ActivityOrder } from 'generated/graphql'

export type OrderByInitialStateType = ActivityOrder

export type OrderByAction = {
  type: 'ORDER_BY_UPDATE'
  orderBy: ActivityOrder
}

const orderByReducer = (
  orderBy: OrderByInitialStateType,
  action: OrderByAction,
) => {
  if (action.type === 'ORDER_BY_UPDATE') {
    return action.orderBy
  }

  return orderBy
}

export default orderByReducer
