interface IFAdKeyInfoType {
  main: {
    pc: string
    mo: string
  }
  communityDetail: {
    pc: string
    mo: string
  }
  communityNative: {
    pc: string
    mo: string
  }
  intern: string
  recruit: string
  activity: string
  contest: string
  education: string
}

export const AD_ID: IFAdKeyInfoType = {
  main: {
    pc: '8',
    mo: '9',
  },
  communityDetail: {
    pc: '10',
    mo: '11',
  },
  communityNative: {
    pc: '12',
    mo: '13',
  },
  intern: '14',
  recruit: '15',
  activity: '16',
  contest: '17',
  education: '18',
}

export const AD_CODE: IFAdKeyInfoType = {
  main: {
    pc: 'pc-main-list-banner',
    mo: 'mobile-main-list-banner',
  },
  communityDetail: {
    pc: 'pc-community-post-detail-banner',
    mo: 'mobile-community-post-detail-banner',
  },
  communityNative: {
    pc: 'pc-community-native-banner',
    mo: 'mobile-community-native-banner',
  },
  intern: 'activity-intern-notice-banner',
  recruit: 'activity-recruit-notice-banner',
  activity: 'activity-activity-notice-banner',
  contest: 'activity-contest-notice-banner',
  education: 'activity-education-notice-banner',
}
