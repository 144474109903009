import { enableES5, produce } from 'immer'
import { ActivityFilter, ActivityFilterItemById } from 'generated/graphql'

export type FilterByStateType = Pick<
  ActivityFilter,
  | 'categoryIDs'
  | 'jobTypes'
  | 'q'
  | 'regionIDs'
  | 'orgTypeIDs'
  | 'internTypeIds'
>

export type FilterByAction =
  | {
      type: 'FILTER_BY_TOGGLE_FILTER_ITEM'
      listItem: ActivityFilterItemById
      fieldName: keyof FilterByStateType
    }
  | {
      type: 'FILTER_BY_REMOVE_FILTER_ITEM'
      listItem: ActivityFilterItemById
      fieldName: keyof FilterByStateType
    }
  | {
      type: 'FILTER_BY_SET_KEYWORD'
      listItem: string
      fieldName: keyof FilterByStateType
    }
  | {
      type: 'FILTER_BY_SET_ALL'
      newState: FilterByStateType
    }
  | {
      type: 'FILTER_BY_RE_INIT'
      state: FilterByStateType
    }
  | {
      type: 'FILTER_BY_INIT'
    }

const filterByReducer = (
  filterBy: FilterByStateType,
  action: FilterByAction,
) => {
  enableES5()
  return produce(filterBy, (draft) => {
    switch (action.type) {
      case 'FILTER_BY_TOGGLE_FILTER_ITEM':
        {
          const updateField = draft[action.fieldName]
          const filterItem = action.listItem
          if (
            updateField &&
            typeof updateField !== 'string' &&
            action.fieldName !== 'q'
          ) {
            const index = updateField?.findIndex(
              (item: any) => item === filterItem.id,
            )
            if (index !== -1) {
              draft[action.fieldName]?.splice(index, 1)
            } else {
              // @ts-ignore
              draft[action.fieldName] = [...updateField, filterItem.id]
            }
          }
        }
        break
      case 'FILTER_BY_REMOVE_FILTER_ITEM':
        {
          const updateField = draft[action.fieldName]
          const filterItem = action.listItem
          if (
            updateField &&
            typeof updateField !== 'string' &&
            action.fieldName !== 'q'
          ) {
            const index = updateField?.findIndex(
              (item: any) => item === filterItem.id,
            )
            if (index !== -1) {
              draft[action.fieldName]?.splice(index, 1)
            }
          }
        }
        break
      case 'FILTER_BY_SET_KEYWORD':
        draft.q = action.listItem || ''
        break
      case 'FILTER_BY_SET_ALL':
        draft.q = action.newState.q
        draft.orgTypeIDs = action.newState.orgTypeIDs
        draft.jobTypes = action.newState.jobTypes
        draft.regionIDs = action.newState.regionIDs
        draft.categoryIDs = action.newState.categoryIDs
        draft.internTypeIds = action.newState.internTypeIds
        break
      case 'FILTER_BY_RE_INIT':
        return action.state
      case 'FILTER_BY_INIT':
        return {
          q: '',
          orgTypeIDs: [],
          jobTypes: [],
          internTypeIds: [],
          categoryIDs: [],
          regionIDs: [],
        }

      default:
        break
    }
  })
}

export default filterByReducer
