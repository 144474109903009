import { useDomain } from 'context'

type TUseCanonical = (asPath?: string) => string

const useCanonical: TUseCanonical = (asPath = '') => {
  const domains = useDomain()

  const { protocol, linkareer } = domains

  const pathname = `${protocol}://${linkareer}${asPath}`

  return pathname
}

export default useCanonical
