import { FC, ReactNode, useContext } from 'react'
import { PureQueryOptions } from '@apollo/client'
import { Typography, IconButton, CircularProgress } from '@material-ui/core'
import clsx from 'clsx'
import format from 'date-fns/format'
import gql from 'graphql-tag'
import get from 'lodash/get'
import take from 'lodash/take'
import Image from 'next/image'
import { useRouter } from 'next/router'
import styled, { css } from 'styled-components'
import {
  ActivityListItem_CurrentUserFragment,
  ActivityListItem_ActivityFragment,
  ActivityRecruitType,
  JobType,
} from 'generated/graphql'
import Skeleton from '@app.components/Skeleton'
import { RecruitListScreenContext } from '@app.feature/recruitList/context'
import { extractInternTypesName } from '@app.feature/recruitList/module/extractInternTypesName'
import { getViewCount } from '@app.feature/recruitList/module/getViewCount'
import useIsWebview from '@app.modules/hooks/useIsWebview'
import { getWebviewHref } from '@app.modules/util'
import { RECRUIT_LOGO_IMAGE_SIZE } from 'app.constants/recruitImageSize'
import ActivityScrap from 'components/ActivityScrap'
import Link, { LinkProps } from 'components/Link'
import OldChip from 'components/OldChip'
import { ActivityTypeID } from 'constants/enums'
import { ACTIVITY_TYPE_TEXT } from 'constants/typeText'
import useKeywordHighlighter from 'hooks/useKeywordHighlighter'
import BookMark from 'svgIcons/BookMark'
import { optimizeImageUrl, imageLoader } from 'utils/optimizeImageUrl'
import { getJobType } from 'utils/utils'

gql`
  fragment ActivityListItem_currentUser on User {
    ...ActivityScrap_currentUser
  }
`

export interface ActivityListItemProps {
  className?: string
  isLoading?: boolean
  activity?: ActivityListItem_ActivityFragment
  currentUser?: ActivityListItem_CurrentUserFragment | null
  showActivityType?: boolean
  showHotIcon?: boolean
  size?: 'medium' | 'large'
  searchKeyword?: string
  renderSecondary?: () => any
  scrapIcon?: ReactNode
  showScrapCount?: boolean
  scrapRefetchQueries?: PureQueryOptions[]
  linkProps?: Partial<LinkProps>
  itemTitle?: string | null
  isRecommend?: boolean
}

const ActivityListItem: FC<ActivityListItemProps> = (props) => {
  const router = useRouter()
  const isWebview = useIsWebview()
  const { state } = useContext(RecruitListScreenContext)

  const {
    className = '',
    size = 'medium',
    isLoading,
    activity,
    currentUser,
    showActivityType = false,
    showHotIcon = false,
    searchKeyword = '',
    scrapIcon: initScrapIcon,
    showScrapCount = true,
    scrapRefetchQueries,
    linkProps,
    itemTitle,
    isRecommend,
  } = props

  const scrapIcon = initScrapIcon ?? (
    <BookMark
      className="icon-bookmark"
      isOn={activity?.isScrapped}
      strokeType="#666666"
    />
  )

  const activityTypeText = get(ACTIVITY_TYPE_TEXT, activity?.type || '', '')

  const isRecruit = activity?.type === ActivityTypeID.RECRUIT
  const isIntern =
    router.pathname.includes('intern') ||
    state.filterBy.jobTypes?.includes(JobType.INTERN)
  const categories = (activity?.categories ?? []).map(
    (category) => category?.name,
  )
  const internTypesCount = activity?.recruitInformations
    .map((info) => {
      return info?.internTypes.length ?? 0
    })
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

  const highlightedTitle = useKeywordHighlighter({
    text: itemTitle || activity?.title || '',
    keyword: searchKeyword,
  })

  const handleClickOrganization = () => {
    if (activity?.manager?.channel) {
      router.push(
        getWebviewHref(`/channel/${activity.manager.channel.url}`, isWebview),
      )
    }
  }

  const viewCount = getViewCount(activity?.viewCount || 0)

  function renderSecondary() {
    if (isRecruit) {
      let categoriesText = '-'
      if (categories.length > 1) {
        categoriesText = '각 부문'
      } else if (categories.length === 1 && categories[0]) {
        categoriesText = categories[0]
      }
      return (
        <div className="info-wrapper-recruit">
          <div className="first-container">
            <div className="job-type-container">
              {isLoading ? (
                <Skeleton height={13} />
              ) : (
                <>
                  {isIntern ? (
                    <Typography color="textPrimary" className="short-info-typo">
                      {extractInternTypesName({
                        internTypesCount,
                        activity,
                      })}
                    </Typography>
                  ) : (
                    <Typography color="textPrimary" className="short-info-typo">
                      {activity?.jobTypes?.length
                        ? getJobType(take(activity.jobTypes, 2))
                        : '-'}
                    </Typography>
                  )}
                </>
              )}
            </div>
            {isLoading ? (
              <Skeleton height={13} width={100} />
            ) : (
              <div className="category-container">
                <Typography color="textPrimary" className="category">
                  {categoriesText}
                </Typography>
              </div>
            )}
          </div>
          <div className="second-container">
            {isLoading ? (
              <Skeleton height={13} />
            ) : (
              <Typography color="textPrimary" className="short-info-typo">
                {activity?.recruitType
                  ? {
                      [ActivityRecruitType.NORMAL]: activity?.recruitCloseAt
                        ? `~ ${format(
                            new Date(activity.recruitCloseAt),
                            'MM.dd',
                          )}`
                        : '-',
                      [ActivityRecruitType.ALWAYS]: '상시채용',
                      [ActivityRecruitType.ASAP]: '채용 시 마감',
                      [ActivityRecruitType.SCHEDULED]: '예정',
                    }[activity.recruitType]
                  : '-'}
              </Typography>
            )}
            {isLoading ? (
              <Skeleton height={13} />
            ) : (
              <Typography color="textPrimary" className="short-info-typo">
                조회 {viewCount}
              </Typography>
            )}
          </div>
        </div>
      )
    }

    return (
      <div className="info-wrapper-other">
        {isLoading ? (
          <>
            <div className="skeleton-wrapper">
              <Skeleton width={40} height={13} />
            </div>
            <Skeleton width={40} height={13} />
          </>
        ) : (
          <>
            <Typography color="textPrimary" className="short-info-typo">
              {activity?.recruitCloseAt
                ? format(activity.recruitCloseAt, '~M.dd')
                : '-'}
            </Typography>
            <Typography
              color="textPrimary"
              className={clsx('short-info-typo', 'view-count')}
            >
              조회 {viewCount}
            </Typography>
          </>
        )}
      </div>
    )
  }

  const imageUrl = isRecruit
    ? activity?.logoImage?.url || ''
    : activity?.thumbnailImage?.url || ''

  return (
    <StyledWrapper
      className={clsx(className, {
        large: size === 'large',
      })}
      data-testid={
        isLoading ? 'activity-list-item-loading' : 'activity-list-item'
      }
      data-activityid={activity?.id}
    >
      <div className="img-wrapper" onClick={handleClickOrganization}>
        {isLoading ? (
          <Skeleton width={60} height={60} />
        ) : (
          <div className="img-thumbnail">
            <Image
              loader={imageLoader}
              width={RECRUIT_LOGO_IMAGE_SIZE}
              height={RECRUIT_LOGO_IMAGE_SIZE}
              alt={activity?.title ?? ''}
              src={optimizeImageUrl({
                url: imageUrl,
                width: RECRUIT_LOGO_IMAGE_SIZE,
                height: 'auto',
              })}
              quality={100}
              objectFit="contain"
            />
          </div>
        )}
      </div>
      <div className="recruit-content">
        <div className="content-wrapper">
          <div className="organization-wrapper">
            {isLoading ? (
              <Skeleton height={13} width={100} />
            ) : (
              <>
                {showHotIcon && (
                  <OldChip
                    className={clsx('chip', 'hot')}
                    classes={{
                      label: 'label-chip',
                    }}
                    label={'HOT'}
                    color="primary"
                    size="small"
                  />
                )}
                {showActivityType && activityTypeText && (
                  <OldChip
                    className="chip"
                    classes={{
                      label: 'label-chip',
                    }}
                    label={activityTypeText}
                    color="primary"
                    size="small"
                  />
                )}
                <div className="container">
                  {isRecommend && (
                    <div className="recommand-tag-container">
                      <span className="recommand-text">추천</span>
                    </div>
                  )}
                  <Typography
                    style={{
                      cursor:
                        activity?.manager?.channel === undefined ||
                        activity?.manager?.channel === null
                          ? 'default'
                          : 'pointer',
                    }}
                    color="textPrimary"
                    className="organization-name"
                    onClick={handleClickOrganization}
                  >
                    {activity?.organizationName}
                  </Typography>
                </div>
              </>
            )}
          </div>
          <div className="recruit-title">
            <Link
              className="link"
              {...(activity?.id
                ? {
                    href: `/activity/[id]`,
                    as: `/activity/${activity?.id}`,
                  }
                : { href: '' })}
              naked
              {...linkProps}
            >
              {isLoading ? (
                <Skeleton height={13} />
              ) : (
                <Typography
                  color="textPrimary"
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: highlightedTitle,
                  }}
                />
              )}
            </Link>
          </div>
          {props?.renderSecondary?.() || renderSecondary()}
        </div>
        <div className="scrap-container">
          <ActivityScrap
            activity={activity}
            currentUser={currentUser}
            refetchQueries={scrapRefetchQueries}
          >
            {({ scrapActivity, loading, scrapCount }) => (
              <>
                <IconButton
                  onClick={scrapActivity}
                  className={clsx('button-scrap', {
                    scrapped: activity?.isScrapped,
                  })}
                  disabled={loading}
                >
                  {!loading ? scrapIcon : <CircularProgress size={18} />}
                </IconButton>
                {showScrapCount &&
                  (isLoading ? (
                    <Skeleton
                      height={13}
                      width={20}
                      className="scrap-count-skeleton"
                    />
                  ) : (
                    <Typography
                      color="textSecondary"
                      className={clsx('scrap-count', {
                        scrapped: activity?.isScrapped,
                      })}
                    >
                      {scrapCount}
                    </Typography>
                  ))}
              </>
            )}
          </ActivityScrap>
        </div>
      </div>
    </StyledWrapper>
  )
}

export default ActivityListItem

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background-color: #ffffff;
    padding: 15px 10px 15px 20px;
    display: none;
    align-items: center;
    position: relative;

    &::after {
      content: '';
      height: 1px;
      width: calc(100% - 40px);
      background-color: #eeeeee;
      display: block;
      position: absolute;
      bottom: 0;
    }

    ${theme.breakpoints.up('md')} {
      padding-left: 0;
    }

    ${theme.breakpoints.down('md')} {
      display: flex;
    }

    &.large {
      padding-top: 20px;
      padding-bottom: 20px;

      ${theme.breakpoints.down('sm')} {
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }

    .img-wrapper {
      width: 60px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      margin-right: 15px;

      .img-thumbnail {
        & img {
          border-radius: 5px;
        }
      }
    }

    .recruit-content {
      min-width: 0;
      display: flex;
      align-items: center;
      flex-grow: 1;

      .content-wrapper {
        min-width: 0;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-grow: 1;
        margin-right: 30px;

        .organization-wrapper {
          width: 100%;
          flex-grow: 0;
          flex-shrink: 0;
          margin-right: 30px;
          margin-bottom: 4px;
          display: flex;
          align-items: center;

          .container {
            width: 100%;
            display: flex;
            align-items: center;

            .recommand-tag-container {
              width: 25px;
              height: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-shrink: 0;
              border-radius: 3px;
              border: 1px solid #ff0066;
              background-color: white;
              margin-right: 5px;
              box-sizing: content-box;
            }

            .recommand-text {
              color: ${theme.color.secondary_1};
              font-size: 11px;
              font-weight: 500;
              line-height: 10px;
            }
          }

          .chip {
            background-color: #bbbbbb;
            border: 0;
            border-radius: 3px;
            margin-right: 5px;
            height: auto;
          }

          .hot {
            background-image: #ef2929;
          }

          .label {
            line-height: 1em;
            font-size: 12px;
            padding: 3px;
            color: #ffffff;
            font-weight: 500;
          }

          .organization-name {
            width: 100%;
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
            font-weight: 500;
          }
        }
      }
    }

    .recruit-title {
      width: 100%;
      flex-grow: 1;
      margin-right: 30px;
      margin-bottom: 4px;

      .link {
        &:hover {
          text-decoration: underline;
        }

        .title {
          font-size: 16px;
          white-space: pre-wrap;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 1.2em;
          text-align: left;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;

          & .highlight {
            font-weight: 600;
          }
        }
      }
    }

    .info-wrapper-recruit {
      display: flex;
      align-items: center;
      max-width: 100%;
      flex-shrink: 0;
      flex-wrap: wrap;

      .first-container,
      .second-container {
        display: flex;

        .job-type-container {
          margin-right: 0;
        }

        .category-container {
          flex-grow: 1;
          display: flex;

          .category {
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
            font-weight: 400;
            padding-right: 10px;
          }
        }

        .short-info-typo {
          font-size: 12px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
          font-weight: 400;
          padding-right: 10px;
        }

        .view-count {
          margin-left: 0;
        }
      }
    }

    .info-wrapper-other {
      max-width: 100%;
      display: flex;
      align-items: center;
      flex-shrink: 0;

      .skeleton-wrapper {
        margin-right: 8px;
      }

      .short-info-typo {
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        font-weight: 400;
        padding-right: 10px;
      }

      .view-count {
        margin-left: 0;
      }
    }

    .scrap-container {
      flex-shrink: 0;

      .button-scrap {
        color: #e8e8e8;
        padding-bottom: 5px;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: ${theme.color.primary_2};
          }
        }

        &.scrapped {
          color: ${theme.color.primary_2};
        }
      }

      .scrap-count-skeleton {
        margin: auto;
      }

      .scrap-count {
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;

        &.scrapped {
          color: ${theme.color.primary_2};
        }
      }

      .icon-bookmark {
        width: 15px;
        height: 15px;
      }
    }

    .dot {
      font-size: 12px;
      font-weight: 300;
      margin-left: 5px;
      margin-right: 5px;
    }
  `}
`
