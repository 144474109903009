import { createContext, Dispatch, FC, Reducer, useReducer } from 'react'
import { isEqual, merge } from 'lodash'
import { useRouter } from 'next/router'
import {
  ActivityOrder,
  ActivityOrderField,
  CategoryActivityType,
  OrderDirection,
  useScreenJobCategoryQuery,
} from 'generated/graphql'
import orderByReducer, {
  OrderByAction,
} from '@app.feature/activityList/module/context/orderByReducer'
import pageReducer, {
  PageAction,
} from '@app.feature/activityList/module/context/pageReducer'
import useJobCategoryStore from '@app.feature/jobCategory/store/store.jobCategory'
import { ActivityTypeID } from 'constants/enums'
import { useSessionFilterInternRecruitParams } from 'hooks/useSessionFilterParams'
import {
  checkSessionFilter,
  parseFilterByParams,
} from '../module/saveSessionFilter'
import activityTypeIdReducer, {
  ActivityTypeIdAction,
} from './activityTypeIdReducer'
import filterByReducer, {
  FilterByAction,
  FilterByStateType,
} from './filterByReducer'

export type FilterByKeys = keyof FilterByStateType

export type StateType = {
  filterBy: FilterByStateType
  orderBy: ActivityOrder
  page: number
  activityTypeId: ActivityTypeID.INTERN | ActivityTypeID.RECRUIT
}

export type Action =
  | FilterByAction
  | OrderByAction
  | PageAction
  | ActivityTypeIdAction

const PAGE_RESET_ACTIONS: Array<Action['type']> = [
  'FILTER_BY_TOGGLE_FILTER_ITEM',
  'FILTER_BY_REMOVE_FILTER_ITEM',
  'ORDER_BY_UPDATE',
]

export const getInitialState = () => {
  return {
    filterBy: {
      q: '',
      orgTypeIDs: [],
      jobTypes: [],
      categoryIDs: [],
      regionIDs: [],
      internTypeIds: [],
    },
    orderBy: {
      field: ActivityOrderField.RECENT,
      direction: OrderDirection.DESC,
    },
    page: 1,
    activityTypeId: ActivityTypeID.RECRUIT,
  } as StateType
}

const RecruitListScreenContext = createContext<{
  state: StateType
  dispatch: Dispatch<Action>
}>({
  state: getInitialState(),
  dispatch: () => {},
})

const init = (initialStateArg: StateType) => {
  return {
    ...merge(getInitialState(), initialStateArg),
  }
}

const mainReducer = (state: StateType, action: Action) => {
  let nextPageState = 1
  if (!PAGE_RESET_ACTIONS.includes(action.type)) {
    nextPageState = pageReducer(state.page, action as PageAction)
  }

  return {
    filterBy: filterByReducer(state.filterBy, action as FilterByAction),
    orderBy: orderByReducer(state.orderBy, action as OrderByAction),
    page: nextPageState,
    activityTypeId: activityTypeIdReducer(
      state.activityTypeId,
      action as ActivityTypeIdAction,
    ),
  }
}

const internFilterByKeys: Array<FilterByKeys> = [
  'q',
  'orgTypeIDs',
  'categoryIDs',
  'regionIDs',
  'internTypeIds',
]

const recruitFilterByKeys: Array<FilterByKeys> = [
  'q',
  'orgTypeIDs',
  'categoryIDs',
  'regionIDs',
  'jobTypes',
  'internTypeIds',
]

export interface RecruitListScreenContextProviderProps {
  initialState?: StateType
  onUpdate?: (state: StateType) => void
}

const RecruitListScreenContextProvider: FC<
  RecruitListScreenContextProviderProps
> = ({ children, initialState = getInitialState(), onUpdate }) => {
  const router = useRouter()
  const recruitType = router.pathname === '/list/intern' ? 'intern' : 'recruit'
  const filterKeys =
    recruitType === 'intern' ? internFilterByKeys : recruitFilterByKeys

  const filterQuery = checkSessionFilter(recruitType, router.query)
  const filterByParams = parseFilterByParams(filterQuery, filterKeys)
  const initCategories = useJobCategoryStore((state) => state.initCategories)

  useScreenJobCategoryQuery({
    variables: {
      filterBy: {
        activityType: CategoryActivityType.RECRUIT,
        parentId: null,
      },
    },
    onCompleted: (data) => {
      if (data.categories) {
        const validCategories = data.categories.filter(
          (category) => category !== null,
        )
        initCategories(validCategories, [...filterByParams.categoryIDs]) // id init
      }
    },
  })

  const memoizedReducer = (state, action) => {
    const newState = mainReducer(state, action)
    if (
      onUpdate &&
      action.type !== 'FILTER_BY_RE_INIT' &&
      !isEqual(newState, state)
    ) {
      onUpdate(newState)
    }
    return newState
  }

  const [state, dispatch] = useReducer<Reducer<StateType, Action>, StateType>(
    memoizedReducer,
    { ...initialState, filterBy: filterByParams },
    init,
  )

  useSessionFilterInternRecruitParams(recruitType)

  return (
    <RecruitListScreenContext.Provider value={{ state, dispatch }}>
      {children}
    </RecruitListScreenContext.Provider>
  )
}

export { RecruitListScreenContext, RecruitListScreenContextProvider }
