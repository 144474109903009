import React, { useContext } from 'react'

type TGoogleAdManagerContextValue = {
  isLoading: boolean
}

export const GoogleAdManagerContext =
  React.createContext<TGoogleAdManagerContextValue>({
    isLoading: false,
  })

export const useGoogleAdManagerContext = (): TGoogleAdManagerContextValue => {
  const context = useContext(GoogleAdManagerContext)

  if (!context) {
    throw new Error(
      `[GoogleAdManagerContext]: You forgot to wrap your component with AdsProvider`,
    )
  }

  return context
}
