import { ActivityListItem_ActivityFragment, JobType } from 'generated/graphql'

type TExtractInternTypesNameProps = {
  internTypesCount?: number
  activity?: ActivityListItem_ActivityFragment
}

export const extractInternTypesName = ({
  internTypesCount,
  activity,
}: TExtractInternTypesNameProps): string => {
  if (internTypesCount === 1) {
    return (
      activity?.recruitInformations?.find(
        (info) => info?.jobType === JobType.INTERN,
      )?.internTypes?.[0]?.name || ''
    )
  } else {
    return '인턴'
  }
}
