import { useMemo } from 'react'
// @ts-ignore
import highlighter from 'keyword-highlighter'

interface UseKeywordHighlighterProps {
  text: string
  keyword?: string
}

function useKeywordHighlighter(props: UseKeywordHighlighterProps) {
  const { text, keyword = '' } = props

  const highlightedText = useMemo(
    () => highlighter(keyword, text),
    [text, keyword],
  )

  return keyword ? highlightedText : text
}

export default useKeywordHighlighter
