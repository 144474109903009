import { ActivityTypeID } from 'constants/enums'

export type ActivityTypeIdAction = {
  type: 'ACTIVITY_TYPE_ID_ACTION'
  activityTypeId: ActivityTypeID.INTERN | ActivityTypeID.RECRUIT
}

const activityTypeIdReducer = (
  activityTypeId: ActivityTypeID.INTERN | ActivityTypeID.RECRUIT,
  action: ActivityTypeIdAction,
) => {
  if (action.type === 'ACTIVITY_TYPE_ID_ACTION') {
    return action.activityTypeId
  }

  return activityTypeId
}

export default activityTypeIdReducer
