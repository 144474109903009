import { GOOGLE_AD_MANAGER_UNIT_PREFIX } from 'utils/config'
import { TAdItem } from '../types'

// 구글 애드매니저 고유 네트워크 코드
const GOOGLE_AD_MANAGER_NETWORK_CODE = '22903531815'

export const GOOGLE_AD_MANAGER_DIV_PREFIX = 'linkareer-google-ad-manager-'

export const GOOGLE_AD_MANAGER_DATA: {
  [key in string]: TAdItem
} = {
  INTERN_PC_1: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}INTERN_PC_1`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}pc_web_intern_1_20230524`,
    sizeMappings: [1100, 102],
  },
  INTERN_PC_2: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}INTERN_PC_2`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}pc_web_intern_2_20230526`,
    sizeMappings: [1100, 102],
  },
  INTERN_PC_3: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}INTERN_PC_3`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}pc_web_intern_3_20230526`,
    sizeMappings: [1100, 102],
  },
  INTERN_PC_4: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}INTERN_PC_4`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}pc_web_intern_4_20230526`,
    sizeMappings: [1100, 102],
  },
  INTERN_MO_1: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}INTERN_MO_1`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}mo_web_intern_1_20230531`,
    sizeMappings: [790, 94],
  },
  INTERN_MO_2: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}INTERN_MO_2`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}mo_web_intern_2_20230531`,
    sizeMappings: [790, 94],
  },
  INTERN_MO_3: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}INTERN_MO_3`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}mo_web_intern_3_20230531`,
    sizeMappings: [790, 94],
  },
  INTERN_MO_4: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}INTERN_MO_4`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}mo_web_intern_4_20230531`,
    sizeMappings: [790, 94],
  },
  RECRUIT_PC_1: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}RECRUIT_PC_1`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}pc_web_recruit_1_20230531`,
    sizeMappings: [1100, 102],
  },
  RECRUIT_PC_2: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}RECRUIT_PC_2`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}pc_web_recruit_2_20230531`,
    sizeMappings: [1100, 102],
  },
  RECRUIT_PC_3: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}RECRUIT_PC_3`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}pc_web_recruit_3_20230531`,
    sizeMappings: [1100, 102],
  },
  RECRUIT_PC_4: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}RECRUIT_PC_4`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}pc_web_recruit_4_20230531`,
    sizeMappings: [1100, 102],
  },
  RECRUIT_MO_1: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}RECRUIT_MO_1`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}mo_web_recruit_1_20230531`,
    sizeMappings: [790, 94],
  },
  RECRUIT_MO_2: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}RECRUIT_MO_2`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}mo_web_recruit_2_20230531`,
    sizeMappings: [790, 94],
  },
  RECRUIT_MO_3: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}RECRUIT_MO_3`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}mo_web_recruit_3_20230531`,
    sizeMappings: [790, 94],
  },
  RECRUIT_MO_4: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}RECRUIT_MO_4`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}mo_web_recruit_4_20230607`,
    sizeMappings: [790, 94],
  },
  CONTEST_PC_1: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}CONTEST_PC_1`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}pc_web_contest_1_20230531`,
    sizeMappings: [266, 386],
  },
  CONTEST_PC_2: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}CONTEST_PC_2`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}pc_web_contest_2_20230531`,
    sizeMappings: [266, 386],
  },
  CONTEST_PC_3: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}CONTEST_PC_3`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}pc_web_contest_3_20230531`,
    sizeMappings: [266, 386],
  },
  CONTEST_PC_4: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}CONTEST_PC_4`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}pc_web_contest_4_20230531`,
    sizeMappings: [266, 386],
  },
  CONTEST_MO_1: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}CONTEST_MO_1`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}mo_web_contest_1_20230531`,
    sizeMappings: [415, 535],
  },
  CONTEST_MO_2: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}CONTEST_MO_2`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}mo_web_contest_2_20230531`,
    sizeMappings: [415, 535],
  },
  CONTEST_MO_3: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}CONTEST_MO_3`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}mo_web_contest_3_20230531`,
    sizeMappings: [415, 535],
  },
  CONTEST_MO_4: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}CONTEST_MO_4`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}mo_web_contest_4_20230531`,
    sizeMappings: [415, 535],
  },
  ACTIVITY_PC_1: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}ACTIVITY_PC_1`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}pc_web_activity_1_20230531`,
    sizeMappings: [266, 386],
  },
  ACTIVITY_PC_2: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}ACTIVITY_PC_2`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}pc_web_activity_2_20230531`,
    sizeMappings: [266, 386],
  },
  ACTIVITY_PC_3: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}ACTIVITY_PC_3`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}pc_web_activity_3_20230531`,
    sizeMappings: [266, 386],
  },
  ACTIVITY_PC_4: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}ACTIVITY_PC_4`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}pc_web_activity_4_20230531`,
    sizeMappings: [266, 386],
  },
  ACTIVITY_MO_1: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}ACTIVITY_MO_1`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}mo_web_activity_1_20230531`,
    sizeMappings: [415, 535],
  },
  ACTIVITY_MO_2: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}ACTIVITY_MO_2`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}mo_web_activity_2_20230531`,
    sizeMappings: [415, 535],
  },
  ACTIVITY_MO_3: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}ACTIVITY_MO_3`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}mo_web_activity_3_20230531`,
    sizeMappings: [415, 535],
  },
  ACTIVITY_MO_4: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}ACTIVITY_MO_4`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}mo_web_activity_4_20230531`,
    sizeMappings: [415, 535],
  },
  MAIN_BANNER_PC_1: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}MAIN_BANNER_PC_1`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}pc_web_main_1_20230531`,
    sizeMappings: [345, 500],
  },
  MAIN_BANNER_PC_2: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}MAIN_BANNER_PC_2`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}pc_web_main_2_20230531`,
    sizeMappings: [345, 500],
  },
  MAIN_BANNER_PC_3: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}MAIN_BANNER_PC_3`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}pc_web_main_3_20230531`,
    sizeMappings: [345, 500],
  },
  MAIN_BANNER_PC_4: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}MAIN_BANNER_PC_4`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}pc_web_main_4_20230531`,
    sizeMappings: [345, 500],
  },
  MAIN_BANNER_PC_5: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}MAIN_BANNER_PC_5`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}pc_web_main_5_20230531`,
    sizeMappings: [345, 500],
  },
  MAIN_BANNER_MO_1: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}MAIN_BANNER_MO_1`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}mo_web_main_1_20230531`,
    sizeMappings: [914, 220],
  },
  MAIN_BANNER_MO_2: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}MAIN_BANNER_MO_2`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}mo_web_main_2_20230531`,
    sizeMappings: [914, 220],
  },
  MAIN_BANNER_MO_3: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}MAIN_BANNER_MO_3`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}mo_web_main_3_20230531`,
    sizeMappings: [914, 220],
  },
  MAIN_BANNER_MO_4: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}MAIN_BANNER_MO_4`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}mo_web_main_4_20230531`,
    sizeMappings: [914, 220],
  },
  MAIN_BANNER_MO_5: {
    divId: `${GOOGLE_AD_MANAGER_DIV_PREFIX}MAIN_BANNER_MO_5`,
    slotId: `/${GOOGLE_AD_MANAGER_NETWORK_CODE}/${GOOGLE_AD_MANAGER_UNIT_PREFIX}mo_web_main_5_20230531`,
    sizeMappings: [914, 220],
  },
}

export const GOOGLE_AD_MANAGER_MAP = {
  INTERN: {
    PC: [
      GOOGLE_AD_MANAGER_DATA.INTERN_PC_1,
      GOOGLE_AD_MANAGER_DATA.INTERN_PC_2,
      GOOGLE_AD_MANAGER_DATA.INTERN_PC_3,
      GOOGLE_AD_MANAGER_DATA.INTERN_PC_4,
    ],
    MO: [
      GOOGLE_AD_MANAGER_DATA.INTERN_MO_1,
      GOOGLE_AD_MANAGER_DATA.INTERN_MO_2,
      GOOGLE_AD_MANAGER_DATA.INTERN_MO_3,
      GOOGLE_AD_MANAGER_DATA.INTERN_MO_4,
    ],
  },
  RECRUIT: {
    PC: [
      GOOGLE_AD_MANAGER_DATA.RECRUIT_PC_1,
      GOOGLE_AD_MANAGER_DATA.RECRUIT_PC_2,
      GOOGLE_AD_MANAGER_DATA.RECRUIT_PC_3,
      GOOGLE_AD_MANAGER_DATA.RECRUIT_PC_4,
    ],
    MO: [
      GOOGLE_AD_MANAGER_DATA.RECRUIT_MO_1,
      GOOGLE_AD_MANAGER_DATA.RECRUIT_MO_2,
      GOOGLE_AD_MANAGER_DATA.RECRUIT_MO_3,
      GOOGLE_AD_MANAGER_DATA.RECRUIT_MO_4,
    ],
  },
  CONTEST: {
    PC: [
      GOOGLE_AD_MANAGER_DATA.CONTEST_PC_1,
      GOOGLE_AD_MANAGER_DATA.CONTEST_PC_2,
      GOOGLE_AD_MANAGER_DATA.CONTEST_PC_3,
      GOOGLE_AD_MANAGER_DATA.CONTEST_PC_4,
    ],
    MO: [
      GOOGLE_AD_MANAGER_DATA.CONTEST_MO_1,
      GOOGLE_AD_MANAGER_DATA.CONTEST_MO_2,
      GOOGLE_AD_MANAGER_DATA.CONTEST_MO_3,
      GOOGLE_AD_MANAGER_DATA.CONTEST_MO_4,
    ],
  },
  ACTIVITY: {
    PC: [
      GOOGLE_AD_MANAGER_DATA.ACTIVITY_PC_1,
      GOOGLE_AD_MANAGER_DATA.ACTIVITY_PC_2,
      GOOGLE_AD_MANAGER_DATA.ACTIVITY_PC_3,
      GOOGLE_AD_MANAGER_DATA.ACTIVITY_PC_4,
    ],
    MO: [
      GOOGLE_AD_MANAGER_DATA.ACTIVITY_MO_1,
      GOOGLE_AD_MANAGER_DATA.ACTIVITY_MO_2,
      GOOGLE_AD_MANAGER_DATA.ACTIVITY_MO_3,
      GOOGLE_AD_MANAGER_DATA.ACTIVITY_MO_4,
    ],
  },
  MAIN_BANNER: {
    PC: [
      GOOGLE_AD_MANAGER_DATA.MAIN_BANNER_PC_1,
      GOOGLE_AD_MANAGER_DATA.MAIN_BANNER_PC_2,
      GOOGLE_AD_MANAGER_DATA.MAIN_BANNER_PC_3,
      GOOGLE_AD_MANAGER_DATA.MAIN_BANNER_PC_4,
      GOOGLE_AD_MANAGER_DATA.MAIN_BANNER_PC_5,
    ].sort(() => Math.random() - 0.5),
    MO: [
      GOOGLE_AD_MANAGER_DATA.MAIN_BANNER_MO_1,
      GOOGLE_AD_MANAGER_DATA.MAIN_BANNER_MO_2,
      GOOGLE_AD_MANAGER_DATA.MAIN_BANNER_MO_3,
      GOOGLE_AD_MANAGER_DATA.MAIN_BANNER_MO_4,
      GOOGLE_AD_MANAGER_DATA.MAIN_BANNER_MO_5,
    ].sort(() => Math.random() - 0.5),
  },
} as const
