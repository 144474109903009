import { FC } from 'react'
import Chip, { ChipProps } from '@material-ui/core/Chip'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import CloseIcon from 'svgIcons/Close'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      borderRadius: 5,
      height: 52,
      fontSize: 15,
    },
    labelSmall: {
      paddingLeft: 15,
      paddingRight: 15,
    },
    colorPrimary: {
      color: '#333333',
      backgroundColor: '#f2faff',
      border: `1px solid #d9e9f3`,
    },
    clickable: {
      '&:active': {
        boxShadow: 'none',
      },
    },
    clickableColorPrimary: {
      '&:hover, &:focus': {
        backgroundColor: '#f2faff',
      },
    },
    deletableColorPrimary: {
      '&:focus': {
        backgroundColor: '#f2faff',
      },
    },
    deleteIcon: {
      marginRight: 15,
      marginLeft: 5,
    },
    deleteIconColorPrimary: {
      color: '#333333',
      width: 10,
      height: 10,
      marginRight: 15,
      marginLeft: 5,
      '&:hover': {
        color: '#000000',
      },
    },
    sizeSmall: {
      height: 39,
      fontSize: 12,
    },
  }),
)

function ChipDeleteIcon({ onClick, ...props }: any) {
  // https://benfrain.com/converting-divs-into-accessible-pseudo-buttons/
  return (
    <div
      role="button"
      aria-pressed="false"
      data-testid="chip-close-button"
      tabIndex={0}
      onClick={onClick}
    >
      <CloseIcon {...props} />
    </div>
  )
}
const OldChip: FC<ChipProps> = (props) => {
  const classes = useStyles()

  return (
    <Chip
      classes={{
        root: clsx(classes.root, props?.classes?.root),
        labelSmall: clsx(classes.labelSmall, props?.classes?.labelSmall),
        colorPrimary: clsx(classes.colorPrimary, props?.classes?.colorPrimary),
        clickable: clsx(classes.clickable, props?.classes?.clickable),
        clickableColorPrimary: clsx(
          classes.clickableColorPrimary,
          props?.classes?.clickableColorPrimary,
        ),
        deletableColorPrimary: clsx(
          classes.deletableColorPrimary,
          props?.classes?.deletableColorPrimary,
        ),
        deleteIcon: clsx(classes.deleteIcon, props?.classes?.deleteIcon),
        deleteIconColorPrimary: clsx(
          classes.deleteIconColorPrimary,
          props?.classes?.deleteIconColorPrimary,
        ),
        sizeSmall: clsx(classes.sizeSmall, props?.classes?.sizeSmall),
      }}
      deleteIcon={<ChipDeleteIcon />}
      {...props}
    />
  )
}

export default OldChip
