export type PageAction = {
  type: 'PAGE_UPDATE'
  page: number
}

const paginationReducer = (page: number, action: PageAction) => {
  if (action.type === 'PAGE_UPDATE') {
    return action.page
  }

  return page
}

export default paginationReducer
