export const RECRUIT_LOGO_IMAGE_SIZE = 60

export const S_BANNER_PC_WIDTH_SIZE = 528
export const S_BANNER_PC_HEIGHT_SIZE = 360

export const S_BANNER_MOBILE_WIDTH_SIZE = 504
export const S_BANNER_MOBILE_HEIGHT_SIZE = 316

export const A_BANNER_PC_WIDTH_SIZE = 390
export const A_BANNER_PC_HEIGHT_SIZE = 265

export const HOT_LINE_BANNER_PC_WIDTH_SIZE = 720
export const HOT_LINE_BANNER_PC_HEIGHT_SIZE = 120

export const HOT_LINE_BANNER_MOBILE_WIDTH_SIZE = 495
export const HOT_LINE_BANNER_MOBILE_HEIGHT_SIZE = 120

export const POPULAR_ACTIVITY_IMAGE_SIZE = 465

export const LINKAREER_MAGAZINE_WIDTH_SIZE = 140
export const LINKAREER_MAGAZINE_HEIGHT_SIZE = 70

export const LINKAREER_MAGAZINE_IMAGE_RENDER_WIDTH = 140
export const LINKAREER_MAGAZINE_IMAGE_RENDER_HEIGHT = 76
