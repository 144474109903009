import { ParsedUrlQuery } from 'querystring'
import { FilterByKeys } from '../context'

export const checkSessionFilter = (
  recruitType: 'intern' | 'recruit',
  query: ParsedUrlQuery,
) => {
  if (typeof window !== 'undefined') {
    const savedQuery = sessionStorage.getItem(`${recruitType}Filter`)
    return savedQuery ? JSON.parse(savedQuery) : query
  }
  return query
}

export const parseFilterByParams = (
  query: ParsedUrlQuery,
  filterKeys: Array<FilterByKeys>,
): { [key: string]: string[] | string } => {
  const filterByParams: { [key: string]: string[] | string } = {}

  filterKeys.forEach((key) => {
    const queryKey = `filterBy_${key}`
    if (query[queryKey]) {
      const value = query[queryKey] ?? ''

      if (Array.isArray(value)) {
        filterByParams[key] = value
      } else {
        filterByParams[key] = [value]
      }
    } else {
      filterByParams[key] = key === 'q' ? '' : []
    }
  })

  return filterByParams
}
