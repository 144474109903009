/**
 * @desc viewCount의 값이 9999보다 크면 '9999+'를 반환합니다.
 * @param {number} viewCount 조회수
 * @returns {number | string}
 */
export const getViewCount = (viewCount: number): number | string => {
  if (viewCount > 9999) {
    return '9999+'
  }
  return viewCount
}
