import { useEffect } from 'react'
import { useRouter } from 'next/router'

type TFilterType =
  | 'activity'
  | 'club'
  | 'contest'
  | 'education'
  | 'intern'
  | 'recruit'

export const useSessionFilterParams = (filterType: TFilterType) => {
  const router = useRouter()

  useEffect(() => {
    // 페이지 로드 시 세션스토리지에서 쿼리 파라미터를 가져와 적용
    const savedQuery = sessionStorage.getItem(`${filterType}Filter`)
    if (savedQuery) {
      const query = JSON.parse(savedQuery)
      router.replace({
        pathname: router.pathname,
        query: query,
      })
    }
  }, [])

  useEffect(() => {
    const handleRouteChange = () => {
      const query = router.query
      if (Object.keys(query).length > 0) {
        sessionStorage.setItem(`${filterType}Filter`, JSON.stringify(query))
      }
    }
    window.addEventListener('beforeunload', handleRouteChange)
    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      window.removeEventListener('beforeunload', handleRouteChange)
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router])
}

export const useSessionFilterInternRecruitParams = (
  filterType: TFilterType,
) => {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = () => {
      const query = router.query
      if (Object.keys(query).length > 0) {
        sessionStorage.setItem(`${filterType}Filter`, JSON.stringify(query))
      }
    }

    window.addEventListener('beforeunload', handleRouteChange)
    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      window.removeEventListener('beforeunload', handleRouteChange)
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router])
}
